import React, { useState } from "react";

import { Link } from "gatsby";

import {
	MDBContainer,
	MDBFormInline,
	MDBNavbar,
	MDBNavbarBrand,
	MDBNavbarNav,
	MDBNavItem,
	MDBNavbarToggler,
	MDBCollapse,
} from "mdbreact";

import Logo from "../../assets/images/img-logo.svg";

export default function MainNavbar() {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<MDBNavbar dark expand="md" className="bg-green">
			<MDBContainer>
				<MDBNavbarBrand>
					<Link to="/">
						<img
							src="https://www.feevale.br/s/conteudo/b3af5fc4-f40d-42cd-bedb-86291657abf3/Feevale-selo55anos-bco.svg"
							className="img-fluid"
							style={{ width: 240 }}
							alt="Universidade Feevale"
							title="Universidade Feevale"
						/>
					</Link>
				</MDBNavbarBrand>

				<MDBNavbarToggler onClick={() => setIsOpen(!isOpen)} />
				<MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
					<MDBNavbarNav right className="text-center">
						<MDBNavItem className="px-3">
							<Link to="/" className="nav-link text-white" style={{ fontWeight: 500 }}>
								Todos avisos
							</Link>
						</MDBNavItem>
						<MDBNavItem className="px-3">
							<a
								href="https://www.feevale.br"
								className="nav-link text-white"
								style={{ fontWeight: 500 }}
							>
								Retornar ao site da Universidade Feevale
							</a>
						</MDBNavItem>
					</MDBNavbarNav>
				</MDBCollapse>
			</MDBContainer>
		</MDBNavbar>
	);
}
